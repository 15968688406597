import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import { useCollection } from '../context/collection';

export default () => {
  const { iconURL } = useCollection();

  return (
    <AppBar position="static">
      <StyledToolbar style={{ backgroundColor: '#f5f5f5' }}>
        <StyledIcon src="././Assets/logo.png" alt="icon" className="Shop__icon" />
      </StyledToolbar>
    </AppBar>
  );
};

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (min-width: 600px) {
    height: 70px;
  }

  @media (min-width: 1080px) {
    height: 80px;
  }
`;

const StyledIcon = styled.img`
  height: 40px;
  width: auto;

  @media (min-width: 600px) {
    height: 50px;
  }

  @media (min-width: 1080px) {
    height: 60px;
  }
`;
